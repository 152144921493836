.more {
    position: fixed;
    bottom: 5%;
    right: 5px;
    font-size: 20px;
    letter-spacing: 0;
    writing-mode: vertical-rl;
    text-orientation: upright;
}

.more::after {
    position: absolute;
    display: block;
    top: 100%;
    right: 0;
    content: "\2193";

}