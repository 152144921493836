.imageContainer {
  position: relative;
  display: inline-block;
}

.previewMask {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, .5);
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s;
}


.previewMask:hover {
  opacity: 1;
}

.previewMaskInfo {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}