@import '~antd/dist/antd.dark.css';

.photo-container {
    position: relative;
    margin-left: 1%;
    margin-right: 1%;
}

.selected-badge {
    position: absolute;
    top: 8px;
    left: 16px;
}