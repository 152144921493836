.logo {
  float: left;
  margin-right: 15px;
}

.logo img {
  width: 100px;
  height: 36px;
}

.nav {
  position: 'fixed';
  z-index: 1; 
  width: '100%';
}


.nav {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 25px;
  padding-left: 25px;
}

.breadcrumb {
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .nav {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media (min-width: 768px) {
  .nav {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media (min-width: 992px) {
  .nav {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media (min-width: 1200px) {
  .nav {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (min-width: 576px) {
  .nav {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .nav {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .nav {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1140px) {
  .nav {
    width: 1100px;
    max-width: 100%;
  }
}
@media (min-width: 1300px) {
  .nav {
    width: 1500px;
    max-width: 100%;
  }
}