.layout {
  overflow: auto;
}

.layout-main {
  overflow: hidden;
  height: 100vh;
}

.container {
  position: relative;
  padding-right: 25px;
  padding-left: 25px;
}

.content {
  flex: 1;
  max-height: calc(100vh - 64px);
}

.breadcrumb {
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (min-width: 576px) {
  .container {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (min-width: 768px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (min-width: 992px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (min-width: 1200px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (min-width: 576px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1140px) {
  .container {
    width: 1100px;
    max-width: 100%;
  }
}

@media (min-width: 1300px) {
  .container {
    width: 1500px;
    max-width: 100%;
  }
}