.box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.image-container {
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.cover-image-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.thumbs {
  height: 90px;
  flex-shrink: 0;
}

.thumb-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;

}

.thumb-item {
  flex: 0 0 auto;
  width: 160px;
  opacity: .5;
  transition: .2s;
  cursor: pointer;
}

.thumb-item:hover {
  opacity: 1;
}

.thumb-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.firstImage {
  z-index: 1;
}

.firstImage[data-animate='left'] {
  transform: translateX(100%);
  transition: transform 1s ease-in-out;
}

.firstImage[data-animate='right'] {
  transform: translateX(-100%);
  transition: transform 1s ease-in-out;
}

.image-name {
  position: absolute;
  bottom: 0;
  left: 15px;
  padding-bottom: 1.6rem;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 300;
  text-shadow: 0 0 5px rgba(0, 0, 0, .3);
  z-index: 2;
}

.image-name[data-animate='disappear'] {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.image-name[data-animate='appear'] {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}


.btn {
  position: absolute;
  width: 36px;
  height: 36px;
  border-color: #000;
  top: 50%;
  z-index: 2;
}

.btn:hover {
  border-color: #5f5f5f;
  cursor: pointer;
}

.prevBtn {
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(45deg);
  left: 15px;

}

.nextBtn {
  border-bottom: 6px solid;
  border-left: 6px solid;
  transform: rotate(-135deg);
  right: 15px;

}